import React from "react";
import { Helmet } from "react-helmet";

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>Faqs | HBL People</title>
        {/* <meta
name="description"
content="A Global community working towards a Common Purpose."
/> */}
      </Helmet>
      <section className="innerpagebanner culture faq">
        <div className="container">
          <div className="in">
            <h1 data-sr="enter top left 0.3s and move 65px wait .3s">Frequently Asked Questions</h1>
            <h2 data-sr="enter top left 0.3s and move 65px wait .6s">
            Find the Answers You Need
            </h2>
          </div>
        </div>
      </section>
      <section className="innersection footprint faq">
        <div className="container">
        <div className="faqpagecontent">
<h2>What are the day-to-day tasks of a Cash Officer/Teller?</h2>
<p>As a Cash Officer at HBL, your day-to-day may vary depending on the specific branch or location, but they generally encompass the core responsibilities of a Cash Officer at HBL. For further details and to apply, please visit: Cash Officers Careers (oraclecloud.com)</p>
<hr/><h2>What are the day-to-day tasks of a Relationship Manager?</h2>
<p>As a Relationship Manager at HBL, your day-to-day tasks may require strong interpersonal skills, financial acumen, and the ability to tailor banking solutions to meet the diverse needs of clients at HBL. For details and to apply, please visit: https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12004 </p>
<hr/><h2>What are the day-to-day tasks of a Branch Manager?</h2>
<p> As a Branch Manager at HBL, your day-to-day tasks may require effective leadership, strong communication skills, and financial acumen are essential for success as a Branch Manager at HBL.For details or to apply , please visit https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12003 </p>
<hr/><h2>What is a female returnship / Waapsi Program</h2>
<p> A female returnship program, also known as a "Waapsi" program, is designed to help women who have taken a break from their careers re-enter the workforce. This program offers structured training, mentorship, networking opportunities, and sometimes flexible work arrangements to support women in transitioning back into their careers after a hiatus. The goal is to provide support, upskilling, and resources to help women regain confidence and advance in their professional lives. For details and to apply, please visit : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12006 </p>
<hr/><h2>Do I need prior banking experience for any role including Cash Officer,  Relationship Manager or Branch Manager role?</h2>
<p> For many roles at HBL, prior banking experience is not required. For instance, entry-level positions like Cash Officer are open to fresh graduates with little to no experience. However, some managerial or specialized roles such as Branch Managers , Relationship Managers , may require relevant experience, typically around 2-5 years. It's best to check the specific job descriptions for each position to understand the requirements. </p>
<hr/><h2>Is there an age limit for job applicants?</h2>
<p>"HBL does not have a universally defined age limit for job applicants. However, the specific requirements can vary depending on the role and level of experience required. Generally, entry-level positions may be more open to younger candidates, while senior or managerial roles may expect candidates to have several years of experience, which could imply a higher age range. </p>
<p> the most accurate information, it is best to refer to the specific job postings on HBL's official careers page. They can provide detailed information regarding any age-related criteria for particular positions. For details please visit: https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" </p>
<hr/><h2>Can I apply for multiple roles simultaneously?</h2>
<p>Yes, you can. </p>
<hr/><h2>What happens if I miss the deadline?</h2>
<p>If you have missed the application deadline for a job at HBL, it may limit your chances of being considered for that specific position. However, you can keep a check if the deadline extends. Meanwhile you can explore other opportunities at https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005 </p>
<hr/><h2>How can I track the status of my application?</h2>
<p>If you have already been contacted by the HBL Team, you can consider contacting the respective HR representative again . Also, keep an eye on your email for any updates from HBL regarding your application status. We may send notifications about the progress of your application or next steps in the hiring process. </p>
<hr/><h2>Are there any fees associated with the application or test?</h2>
<p>No, there arent. </p>
<hr/><h2>Can I reapply if I have been rejected before?</h2>
<p>Ofcourse you can.  Please visit us at : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005 to explore other opportunities </p>
<hr/><h2>What documents do I need to submit?</h2>
<p>Minimal documents are required which include your identification and educational documents and employment record (if any). In the event of any area or job related requirement, your HR Recruiter will inform you accordingly.</p>
<hr/><h2>I don’t have a candidate ID. How can I create a candidate ID?</h2>
<p>It would be very convenient for us to stay in touch if you have a candidate ID. Don’t worry, you can generate one by creating your profile at:  General Profile Creation - HBL People Careers (oraclecloud.com). Once you have successfully submitted your profile, your candidate ID will be communicated to you through an email.</p>
<hr/><h2>I don’t remember or know my candidate ID. How can I find out my candidate ID?</h2>
<p>It would be very convenient for us to stay in touch if you have a candidate ID. Don’t worry, you can try logging in at with the email address you have previously created your profile with at Manage Your Profile - HBL People Careers (oraclecloud.com) and you will be guided the steps further on how to manage your profile. You can also check your email inbox for the previous communication with you in this regard.</p>
<hr/><h2>What type of questions are included in the assessment?</h2>
<p>The assessment at HBL typically includes a combination of questions that evaluate various skills, knowledge, and competencies based on the role which you are applying for. Be prepared to demonstrate your skills, knowledge, and fit with HBL's culture and values.</p>
<hr/><h2>Are calculators or other tools allowed during the test?</h2>
<p>Before the assessment begins, clear instructions regarding what is and isn’t allowed will be provided. It's essential to read these guidelines carefully to avoid any misunderstandings. Please check any official communications you have received about the assessment.</p>
<hr/><h2>How soon will I receive my test results?</h2>
<p>If you have cleared the assessment you will be contacted by HR as guided at the end of the assessment </p>
<hr/><h2>Will there be a group discussion round in the selection process?</h2>
<p>Whether there will be a group discussion round in the selection process at HBL, can depend on the specific role you are applying for and the overall recruitment process followed. </p>
<hr/><h2>Is the test available in different languages?</h2>
<p>Yes you can opt to attempt the assessment in either English or Urdu.</p>
<hr/><h2>How to register for an assessment?</h2>
<p>If you are fresh graduate, once you apply, you will be notified through an email if you need to attend the assessment.</p>
<hr/><h2>I am unable to attempt the gamified assessment / test?</h2>
<p>Please refer to the guidelines mentioned in the email.</p>
<hr/><h2>The link of the test / gamified assessment is not working</h2>
<p>Please reach out to HBL's HR representative whom you have been in touch with or check the communications you received regarding the assessment for detailed information.</p>
<hr/><h2>I am unable to track or find the email of the gamified assessment</h2>
<p>You can refer to any communications you have received regarding the assessment for detailed information, in some cases, it might have landed into your junk / spam email folder. Please check relevant folders and refer to the communication.</p>
<hr/><h2>What is the process of selection at HBL?</h2>
<p>At HBL, we have a structured selection process that includes multiple stages, such as an initial screening, assessment tests (for fresh graduates), interviews, and possibly group discussions or panel interviews, which depends on the role you are applying for. Once HR contacts you, they will brief you over the process / next steps</p>
<hr/><h2>What is the Turnaround time (TAT) for hiring process? How long does the hiring process or entire recruitment process take?</h2>
<p>The turnaround time (TAT) for the hiring process at HBL varies based on the role which you are applying for. In trainee program or batch hiring, the timelines are specified. However. for any other role, the duration includes the completion of various steps as per the bank's policies and requirements.</p>
<hr/><h2>Can I drop my resume at HBL?</h2>
<p>The applications are accepted online through our HBLPeople.com. You can explore all the open opportunities at : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005. </p>
<hr/><h2>How can I sign my offer letter?</h2>
<p>If you are selected Congratulations! Our HR will send you an official offer letter. This may be sent via email or through a physical copy, depending on the process. Your Talent Acquisition advisor will inform you.</p>
<hr/><h2>How long is the training period for new hires?</h2>
<p>"The training period would depend on the specific role and department. During this time, new employees undergo various training programs designed to help them understand the bank's operations, policies, and culture.</p>
<p>The training may include:</p>
<ol>
<li> Orientation Sessions: Introduction to the bank's values, mission, and organizational structure.</li>
<li> On-the-Job Training: Practical training in specific tasks and responsibilities related to the job.</li>
<li>Skill Development: Workshops and courses aimed at enhancing professional skills relevant to the role.</li>
<li>Mentorship: Pairing with experienced employees for guidance and support.</li>
</ol>
<p>It’s always a good idea to confirm the exact duration and structure of the training program with the HR Function or your hiring manager, as it may vary based on the position and departmental needs." </p>
<hr/><h2>Where will the training for a new employee be conducted?</h2>
<p>The training for new hires at HBL is typically conducted at various locations, depending on the nature of the training and the specific role. This can be a a combination of classroom, online / e-learning and on-the-job exposure.</p>
<hr/><h2>Where should I report on my first day?</h2>
<p>Starting a new job is an exciting experience and being prepared for your first day at HBL can help you make a positive impression. All the requirements are mentioned in the email shared with you with your joining details. All the best!</p>
<hr/><h2>Can I request a different joining date if needed?</h2>
<p>Sure, please reach out to the HR Onboarding department or the hiring manager, It’s best to do this as soon as you know you need to change your joining date.</p>
<hr/><h2>What happens if I am unable to join on the given date?</h2>
<p>Please reach out to the HR Onboarding department or the hiring manager, It’s best to do this as soon as you know you need to change your joining date.</p>
<hr/><h2>What training programs does HBL offer for career growth?</h2>
<p>HBL strives to create an engaged, high-performing workforce where constant learning and growth are paramount. Visit our Learning and Development page to learn more: https://hblpeople.com/learning </p>
<hr/><h2>Are there any certifications that HBL provides?</h2>
<p>HBL recognizes the importance of continuous professional development and may offer various certifications and training programs to enhance the skills and knowledge of its employees. While specific certifications can vary based on the job requirements, do explore our updates at https://hblpeople.com/learning, once you are onboarded. </p>
<hr/><h2>Does HBL sponsor further education or professional courses?</h2>
<p>HBL recognizes the importance of continuous professional development and may offer various certifications and training programs to enhance the skills and knowledge of its employees. While specific certifications can vary based on the job requirements, do explore our updates at https://hblpeople.com/learning, once you are onboarded. </p>
<hr/><h2>Can I choose the branch location I prefer to work at?</h2>
<p>When applying for a position, you may have the opportunity to submit application for your preferred location. Please do visit : https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005 for relevant opportunities.</p>
<hr/><h2>What should I do if my application portal is not working?</h2>
<p>"Sometimes we do have planned downtimes due to maintenance and periodic upgrade to enhance the customer experience. However, if you are experiencing issues with the application portal, here are some steps you can take to resolve the problem:</p>
<ol>
<li>Check the stability of your internet connection</li>
<li>Clear browser cache and cookies</li>
<li> Disable browser extensions</li>
<li>Try a different device</li>
</ol>               
<p>By following these steps, you should be able to troubleshoot the issue with the application portal and get the assistance you need to complete your application process."</p>
<hr/><h2>Does HBL support part-time, remote, work from home or hybrid work arrangements?</h2>
<p>HBL recognizes the importance of flexible work arrangements and supports various options, including remote, work from home, and hybrid models, based on the requirments of the role. These arrangements are designed to accommodate the diverse needs of employees while maintaining productivity and collaboration. </p>   
<hr/><h2>What is the retirement age at HBL?</h2>
<p>The retirement age at HBL is set at 60 years.</p>
<hr/><h2>What is the difference between Retirement and Service Age?</h2>
<p>The retirement age at HBL is set at 60 years, whereas HBL has raised its service age to 65 years. For more detailed information about the benefits offered, you can visit HBL Employee Benefits, https://hblpeople.com/benefits </p>
</div>
        </div>
      </section>
    </>
  );
};

export default Faq;
