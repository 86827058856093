import React from "react";
import { Helmet } from "react-helmet";
import Footertabagp from "../../components/footertabagp";

const Leagueagplookfor = () => {
  return (
    <>
      <Helmet>
        <title>What do we look for | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="lookfor-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 d-none d-lg-block">
              <a
                href="/the-league/accelerate-graduate-programme"
                className="rthome"
              >
                <img src="/images/theleague/innerpage-back-button-arrow.png" /> Accelerate Graduate Programme
              </a>
            </div>
            <div className="col-lg-4">
              <a href="/the-league">
                <img
                  src="/images/theleague/the-league-logo.png"
                  className="img-fluid d-block mx-auto"
                />
              </a>
            </div>
            <div className="col-lg-4 d-none d-lg-block text-end">
              <a href="/the-league/" className="rthome">
                Return to Home <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="im-banner text-center">
                <h2>What do we look for?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <h3>
                HBL handpicks each member of The League and selects individuals
                with values mirroring its own.{" "}
              </h3>
            </div>
          </div>
          <div className="row text-center my-4">
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="in-im">
                <img
                  src="/images/theleague/mt/innerpage-yellow-icon1.png"
                  className="img-fluid mx-auto"
                />
              </div>
              <p>
                In members of The League, we look for graduates who are
                passionate about our business model and strive towards
                excellence.
              </p>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="in-im">
                <img
                  src="/images/theleague/mt/innerpage-yellow-icon2.png"
                  className="img-fluid mx-auto"
                />
              </div>
              <p>
                From the get go, The Accelerate Graduates are immersed in
                training programs and rotations and are thus expected to be
                quick learners that are eager to progress.
              </p>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="in-im">
                <img
                  src="/images/theleague/mt/innerpage-yellow-icon3.png"
                  className="img-fluid mx-auto"
                />
              </div>
              <p>
                HBL has a deep appreciation for Graduates who enter the
                organization with fresh ideas and unique perspectives.
              </p>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="in-im">
                <img
                  src="/images/theleague/mt/innerpage-yellow-icon4.png"
                  className="img-fluid mx-auto"
                />
              </div>
              <p>
                We seek out individuals who are excellent communicators and have
                creative outlooks.
              </p>
            </div>
          </div>
          <p>
            When you join HBL, you’ll be working on tangible projects and
            gaining experience in Pakistan’s most successful financial
            organization!
          </p>
          <div className="col mb-5">
            <div className="leagueacc accordion" id="yourroleacc">
              <div className="accordion-item">
                <h2 className="accordion-header" id="ha">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ca"
                    aria-expanded="false"
                    aria-controls="ca"
                  >
                    Eligibility
                  </button>
                </h2>
                <div
                  id="ca"
                  className="accordion-collapse collapse"
                  aria-labelledby="ha"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>Age: 24-29 years</li>
                      <li>
                        Qualification: Master’s degree with between 2 – 4 years
                        of experience (preferably between Bachelor’s and
                        Master’s degree).
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hb">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cb"
                    aria-expanded="false"
                    aria-controls="cb"
                  >
                    Apply Now
                  </button>
                </h2>
                <div
                  id="cb"
                  className="accordion-collapse collapse"
                  aria-labelledby="hb"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <p>
                    The application period for The Accelerate Graduate programme will begin from 1<sup>st</sup> Feb 2025.
                    </p>

                    <a
                      class="rthome"
                      href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12001/job/14914/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Apply Now
                    </a>
                    <div className="d-block mb-3">
                      <a
                        class="rthome"
                        href="/the-league/agp-application-process-faqs/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Application Process FAQs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="hc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cc"
                    aria-expanded="false"
                    aria-controls="cc"
                  >
                    Contact Us
                  </button>
                </h2>
                <div
                  id="cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="hc"
                  data-bs-parent="#yourroleacc"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                      For more information or queries regarding The League Accelerate Graduate Program, please contact us on:
                      </li>
                      <li>theleague@hbl.com</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex d-lg-none text-center my-3">
            <div className="col">
              <a
                href="/the-league/accelerate-graduate-programme"
                className="rthome"
              >
                <img src="/images/theleague/innerpage-back-button-arrow.png" /> Accelerate Graduate Programme
              </a>
            </div>
            <div className="col ">
              <a href="/the-league/" className="rthome">
                Return to Home
                <img src="/images/theleague/innerpage-forward-button-arrow.png" />
              </a>
            </div>
          </div>
        </div>
        <Footertabagp />
      </section>
    </>
  );
};

export default Leagueagplookfor;
