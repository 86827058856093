import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Interndata from "../../components/Interndata";
import Journey from "../../components/Journey";

const Leagueinternship = () => {
  useEffect(() => {
    $(".yway #a").hover(function () {
      $(".yway .aa").addClass("show1");
      $(".yway .aa").removeClass("hide1");
      $(".yway .bb").addClass("hide1");
      $(".yway .cc").addClass("hide1");
      $(".yway .dd").addClass("hide1");
      $(".yway .dd").removeClass("show1");
      $(".yway .bb").removeClass("show1");
      $(".yway .cc").removeClass("show1");
    });
    $(".yway #b").hover(function () {
      $(".yway .bb").addClass("show1");
      $(".yway .bb").removeClass("hide1");
      $(".yway .aa").addClass("hide1");
      $(".yway .cc").addClass("hide1");
      $(".yway .dd").addClass("hide1");
      $(".yway .aa").removeClass("show1");
      $(".yway .dd").removeClass("show1");
      $(".yway .cc").removeClass("show1");
    });
    $(".yway #c").hover(function () {
      $(".yway .cc").addClass("show1");
      $(".yway .cc").removeClass("hide1");
      $(".yway .bb").addClass("hide1");
      $(".yway .aa").addClass("hide1");
      $(".yway .dd").addClass("hide1");
      $(".yway .aa").removeClass("show1");
      $(".yway .bb").removeClass("show1");
      $(".yway .dd").removeClass("show1");
    });
    $(".yway #d").hover(function () {
      $(".yway .dd").addClass("show1");
      $(".yway .dd").removeClass("hide1");
      $(".yway .bb").addClass("hide1");
      $(".yway .cc").addClass("hide1");
      $(".yway .aa").addClass("hide1");
      $(".yway .aa").removeClass("show1");
      $(".yway .bb").removeClass("show1");
      $(".yway .cc").removeClass("show1");
    });
    $(function () {
      $(".paralax").each(function () {
        var $bgobj = $(this);
        $(window).scroll(function () {
          var offset = $bgobj.offset();
          var elemTop = offset.top;
          var windowBottom = $(window).scrollTop() + $(window).height();
          var elemRel = elemTop - $(window).scrollTop();
          var yPos = elemRel / $bgobj.data("speed");
          var coords = "50% " + yPos + "px";
          if (
            (elemRel < 0 || elemRel < $(window).height()) &&
            elemRel > -$bgobj.height()
          ) {
            $bgobj.css({ backgroundPosition: coords });
          }
        });
      });
    });
    new window.Swiper(".internslide", {
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1550: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Internship | THE LEAGUE</title>
        <link rel="stylesheet" href="/css/font.css" />
        <link rel="stylesheet" href="/css/theleague.css" />
      </Helmet>
      <section className="intern-page">
        <div className="skill">
          <div className="container ">
            <h1>Do you have what it takes?</h1>
            <h2>Key Skills</h2>
            <div className="row">
              <div className="col-md-3 col-lg-3 a1">
                <div className="image a">
                  <img
                    src="/images/theleague/internship/skills-icon1.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Excellent Communication Skills</h4>
                  </div>
                </div>
                <div className="in aa ">
                  <p>Can effectively develop a rapport with audience. </p>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 b1">
                <div className="image b">
                  <img
                    src="/images/theleague/internship/skills-icon2.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Analytical Skills</h4>
                  </div>
                </div>
                <div className="in bb ">
                  <p>
                    Can effectively evaluate and interpret available
                    information.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 c1">
                <div className="image c">
                  <img
                    src="/images/theleague/internship/skills-icon3.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Problem Solving</h4>
                  </div>
                </div>
                <div className="in cc ">
                  <p>
                    Can work through details of a problem to reach a solution.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 d1">
                <div className="image d">
                  <img
                    src="/images/theleague/internship/skills-icon4.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Decision Making</h4>
                  </div>
                </div>
                <div className="in dd">
                  <p>Can make wise choices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="valued">
          <div className="container">
            <h2>Valued Attributes</h2>
            <div className="row row-cols-1 row-cols-md-5">
              <div className="col a1">
                <div className="im a">
                  <img
                    src="/images/theleague/internship/attributes-icon1.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Collaborative</h4>
                  </div>
                </div>
                <div className="in aa">
                  <p>Can productively work with others.</p>
                </div>
              </div>
              <div className="col b1">
                <div className="im b">
                  <img
                    src="/images/theleague/internship/attributes-icon2.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Assertive</h4>
                  </div>
                </div>
                <div className="in bb">
                  <p>Able to stand up for what you believe in. </p>
                </div>
              </div>
              <div className="col c1">
                <div className="im c">
                  <img
                    src="/images/theleague/internship/attributes-icon3.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Innovative</h4>
                  </div>
                </div>
                <div className="in cc">
                  <p>Thinks out of the box. Literally!</p>
                </div>
              </div>
              <div className="col d1">
                <div className="im d">
                  <img
                    src="/images/theleague/internship/attributes-icon4.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Positive</h4>
                  </div>
                </div>
                <div className="in dd">
                  <p>Focuses on the constructive, optimistic side.</p>
                </div>
              </div>
              <div className="col e1">
                <div className="im e">
                  <img
                    src="/images/theleague/internship/attributes-icon5.png"
                    className="d-block mx-auto"
                  />
                  <div>
                    <h4>Result Oriented</h4>
                  </div>
                </div>
                <div className="in ee">
                  <p>Determined to deliver outcomes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <h2>EXPLORE WITH US</h2>
                <p>
                  The League Internship Programme is designed to help you
                  explore our organization and gain hands-on-experience to
                  evaluate future career possibilities with Pakistan’s largest
                  financial service organization.
                </p>
                <p>
                  Spend your summer in a dynamic environment working on real
                  time applications of financial and economic principles.
                </p>
                <p>
                  Engage with industry leaders to broaden your skill set through
                  a variety of work experience in product innovation, research
                  and analytics, marketing and brand management, human resources
                  and relationship management.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p>
          <img
            src="/images/theleague/internship/explore-image-right1.jpg"
            className="img-fluid mx-auto d-block d-lg-none"
          />
        </p>
        <div className="yway">
          <div className="container">
            <div className="row">
              <h2>Your way in</h2>
              <div className="yway-but">
                <li>
                  <a id="a">
                    <img src="/images/theleague/internship/way-in-icon1.png" />
                  </a>
                </li>
                <li>
                  <img src="/images/theleague/internship/way-in-arrow.png" />
                </li>
                <li>
                  <a id="b">
                    <img src="/images/theleague/internship/way-in-icon2.png" />
                  </a>
                </li>
                <li>
                  <img src="/images/theleague/internship/way-in-arrow.png" />
                </li>
                <li>
                  <a id="c">
                    <img src="/images/theleague/internship/way-in-icon3.png" />
                  </a>
                </li>
                <li>
                  <img src="/images/theleague/internship/way-in-arrow.png" />
                </li>
                <li>
                  <a id="d">
                    <img src="/images/theleague/internship/way-in-icon4.png" />
                  </a>
                </li>
              </div>
              <div className="mx-auto col-md-4 col-lg-4">
                <div className="inn">
                  <div className="in aa hide1">
                    <img
                      src="/images/theleague/internship/way-in-icon1.png"
                      className="img-fluid mx-auto d-block d-lg-none"
                    />
                    <h4>Make the first move</h4>
                    <p>
                      Submit an official application and wait to hear from us.
                    </p>
                  </div>
                  <div className="in bb hide1">
                    <img
                      src="/images/theleague/internship/way-in-icon2.png"
                      className="img-fluid mx-auto d-block d-lg-none"
                    />
                    <h4>Ace the assessment</h4>
                    <p>
                      Show us that you have the required skill set to be a part
                      of our sought after HBL Internship Programme.
                    </p>
                  </div>
                  <div className="in cc hide1">
                    <img
                      src="/images/theleague/internship/way-in-icon3.png"
                      className="img-fluid mx-auto d-block d-lg-none"
                    />
                    <h4>Impress the interviewers</h4>
                    <p>
                      We want to know you better. Tell us about your
                      accomplishments and aspirations.
                    </p>
                  </div>
                  <div className="in dd show1">
                    <img
                      src="/images/theleague/internship/way-in-icon4.png"
                      className="img-fluid mx-auto d-block d-lg-none"
                    />
                    <h4>Open an offer letter</h4>
                    <p>
                      Congratulations! You have earned a place in our progamme.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial parallax bg-img-1" data-speed="2">
          <h2>Why Us?</h2>
          <div className="container">
            <div
              id="carousel-example-generic"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Syed-Azlan-Bukhari.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Syed Azlan Bukhari</h4>
                        <p>
                          Institute of Business Administration
                          <br />
                          Department: Risk Management <br />
                          2021
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Syed-Azlan-Bukhari.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Syed Azlan Bukhari</h4>
                        <p>
                          Institute of Business Administration
                          <br />
                          Department: Risk Management <br />
                          2021
                        </p>
                      </div>
                    </div>
                    <h3>
                      My experience with HBL has been one filled with kindness
                      and support. From my first day to my last, everyone from
                      my department as well as HR has been supportive and
                      attentive, making sure to heed to each one of my inquiries
                      and issues. My experience with HBL has not only taught me
                      the importance of an excellent corporate culture, but also
                      the importance of taking care of your fellow co-workers. I
                      am grateful for all I’ve learnt and would recommend The
                      League to anyone seeking an internship with the banking
                      sector above all else.
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Kashaf-Ejaz.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Kashaf Ejaz</h4>
                        <p>
                          Institute of Business Administration
                          <br />
                          Department: Finance <br />
                          2021
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Kashaf-Ejaz.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Kashaf Ejaz</h4>
                        <p>
                          Institute of Business Administration <br />
                          Department: Finance <br />
                          2021
                        </p>
                      </div>
                    </div>
                    <h3>
                      Even in the current challenging situation, the program was
                      very well executed. My 6-week internship was an amazing
                      journey, and I was assigned projects which provided me
                      with tremendous exposure.
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/sarmad.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Muhammad Sarmad Naeem</h4>
                        <p>
                          Institute of Business Administration
                          <br />
                          Department: Innovation &amp; Financial Inclusion
                          <br />
                          2018
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/sarmad.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Muhammad Sarmad Naeem</h4>
                        <p>
                          Institute of Business Administration <br />
                          Department: Innovation &amp; Financial Inclusion
                          <br />
                          2018
                        </p>
                      </div>
                    </div>
                    <h3>
                      My experience as an intern at HBL has been wonderful. It
                      has been a great mix of learning, engagement and
                      development and has given me a good taste of how the
                      corporate life looks like. Working at HBL, I experienced
                      the best of the banking industry and have developed the
                      necessary skills to succeed in the corporate environment.
                      It has taught me how to manage tasks, time and the
                      expectations of your supervisors.
                      <br />
                      The takeaway for me from this amazing programme has been
                      to interact and make connections, friends and enjoy what
                      you do. Always be open to learning opportunities and treat
                      yourself as a part of something bigger as I have felt
                      during my time here at HBL.
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Ashar-Ali-Khan.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Ashar Ali Khan</h4>
                        <p>
                          Institute of Business Administration
                          <br />
                          Department: Human Resources
                          <br />
                          2019
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Ashar-Ali-Khan.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Ashar Ali Khan</h4>
                        <p>
                          Institute of Business Administration <br />
                          Department: Human Resources
                          <br />
                          2019
                        </p>
                      </div>
                    </div>
                    <h3>
                      My internship at HBL has been nothing short of amazing. I
                      was constantly exposed to challenging tasks in HR that
                      helped me evolve my understanding of the role of HR in the
                      banking industry. I also got to experience the open
                      culture at HBL. The League programme was filled with
                      thorough learning opportunities that I believe will make
                      my transition to the corporate world much easier after I
                      graduate. During this six-week period, I made very fond
                      memories that I will surely cherish forever.
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/zahra.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Zahra Shahbaz Ladak</h4>
                        <p>
                          Institute of Business Administration
                          <br />
                          Department: Corporate Banking
                          <br />
                          2018
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/zahra.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Zahra Shahbaz Ladak</h4>
                        <p>
                          Institute of Business Administration <br />
                          Department: Corporate Banking
                          <br />
                          2018
                        </p>
                      </div>
                    </div>
                    <h3>
                      The six weeks at HBL has been quite a journey for me. It
                      gave me a flavor of the real world and helped reinforce my
                      knowledge, responsibilities, focus, drive and ambitions.
                      <br />
                      It was an exhilarating experience filled with personal
                      development and professional grooming. The friendly and
                      welcoming environment and culture at HBL gave me a sense
                      of belonging from the very first day. My time at HBL were
                      a source of immense learning and exposure as I was able to
                      utilize all the skills in real life.
                      <br />
                      Through The League Internship Programme, I was able to
                      develop and expand my skillset. The time spent here has
                      been tremendously valuable and when I reflect on all that
                      I have learnt, I realize what an excellent experience this
                      has been. I now feel more prepared to achieve my goals and
                      secure the career I have always dreamt about.
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w3 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Syed-Ammar-Ali.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Syed Ammar Ali Shah </h4>
                        <p>
                          Institute of Business Management <br />
                          Department: Corporate Affairs <br />
                          2019
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Syed-Ammar-Ali.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Syed Ammar Ali Shah</h4>
                        <p>
                          Institute of Business Management <br />
                          Department: Corporate Affairs <br />
                          2019
                        </p>
                      </div>
                    </div>
                    <h3>
                      HBL’s The League Programme gave me the opportunity to work
                      on real-time projects under the mentorship of the
                      industry’s best talent. HBL’s culture instills a sense of
                      ownership which pushed me to take initiative and become an
                      integral part of my department. For me, this experience
                      was a journey of self-discovery and will mold my career
                      path for years to come.
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w4 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/tehreem.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          Syeda Tehreem Zahra Jaffri
                        </h4>
                        <p>
                          LUMS <br />
                          Department: Corporate Banking <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/tehreem.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">
                          Syeda Tehreem Zahra Jaffri
                        </h4>
                        <p>
                          LUMS <br />
                          Department: Corporate Banking <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <h3>
                      Interning at HBL gave me an opportunity to experience what
                      professional life would hold for me in the future. Even
                      though it was different, and slightly difficult, due to
                      the current situation leading to work from home, I was
                      able to interact with a team of great people – experts in
                      the field – and learn from them.
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w4 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Ashar-Ali-Khan.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Aarass Nadeem</h4>
                        <p>
                          IBA- Karachi <br />
                          Department: Global Operations
                          <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Ashar-Ali-Khan.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Aarass Nadeem</h4>
                        <p>
                          IBA- Karachi <br />
                          Department: Global Operations <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <h3>
                      I consider myself very fortunate to get an internship
                      during the Covid – 19 wave, and that too at a corporation
                      like HBL. It’s commendable how in-spite of all odds this
                      year, they continued with the recruitment. The internship
                      managers and my department head were extremely
                      accommodating with work from home, and this has been an
                      excellent opportunity for my personal and professional
                      growth. Also despite the pandemic, I made great friends
                      through this internship and learnt to adapt to the new
                      normal.
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w4 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/muhammad-sarim.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Muhammad Sarim</h4>
                        <p>
                          IBA – Karachi <br />
                          Department: Corporate Banking – KHI <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/muhammad-sarim.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Muhammad Sarim</h4>
                        <p>
                          IBA – Karachi <br />
                          Department: Corporate Banking – KHI <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <h3>
                      When I got selected for this opportunity my excitement
                      came not only from the fact that it was HBL, one of the
                      biggest financial institution in the country but also from
                      the thought of being able to get experience alongside some
                      of the most learned individuals within the financial
                      sector.&nbsp; The projects I got helped me create an
                      understanding of the Pakistani marketplace which will no
                      doubt assist me greatly in my future career. This
                      internship also gave me the opportunity to implement my
                      theoretical knowledge as well as create connections that I
                      hope to keep for a long time to come!
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/taha-waqar.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Taha Waqar Alam </h4>
                        <p>
                          NUST Karachi <br />
                          Department: Human Resources Project Management <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/taha-waqar.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Taha Waqar Alam </h4>
                        <p>
                          NUST Karachi <br />
                          Department: Human Resources Project Management <br />
                          2020
                        </p>
                      </div>
                    </div>
                    <h3>
                      Interning at HBL was a unique experience since it allowed
                      me to explore outside my own field and understand the
                      integration of IT in HR mainly through research. It also
                      helped familiarize me with work in a professional
                      environment despite the unusual circumstances. I am
                      grateful to HBL for this opportunity and look forward to
                      working at HBL amidst an ever-supportive team.
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/M-AHMAD-JAMAL.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">M. Ahmad Jamal</h4>
                        <p>
                          NUST Karachi
                          <br />
                          Masters – IBA
                          <br />
                          Department: Operations Services
                          <br />
                          2022
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/M-AHMAD-JAMAL.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">M. Ahmad Jamal</h4>
                        <p>
                          NUST Karachi
                          <br />
                          Masters – IBA
                          <br />
                          Department: Operations Services
                          <br />
                          2022
                        </p>
                      </div>
                    </div>
                    <h3>
                      My internship at HBL was the highlight of my summer. I was
                      placed in a department with many verticals such as
                      personal, auto, housing and corporate loans. The rotations
                      through every vertical made me realize that these areas
                      are very different from each other and the opportunities
                      to learn were as many as I wanted them to be. My
                      colleagues were super supportive and helpful, my managers
                      were as understanding. It felt like I was a part of the
                      organization, not just an internee who’s here for just 6
                      weeks. This experience and these learnings will stay with
                      me for the rest of my life!
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="carousel-item active">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Haseeb-Ahmad-Khan.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Haseeb Ahmad Khan</h4>
                        <p>
                          Karabük University
                          <br />
                          Department: Branch Banking
                          <br />
                          2022
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Haseeb-Ahmad-Khan.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">Haseeb Ahmad Khan</h4>
                        <p>
                          Karabük University
                          <br />
                          Department: Branch Banking
                          <br />
                          2022
                        </p>
                      </div>
                    </div>
                    <h3>
                      “As my internship draws to a close and I reflect back on
                      all I have learned, I realize what an excellent experience
                      this has been. I gathered much knowledge in the internship
                      , but a hands-on approach has been invaluable. It has
                      served as a beneficial ending to my formal education. In
                      my opinion, the best way to learn is by doing. I am truly
                      grateful for this opportunity.”
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="test-in">
                    <div className="media w1 d-none d-lg-block">
                      <div className="media-left">
                        <img
                          className="media-object"
                          src="/images/theleague/internship/Syed-Kazim-Abbas-Abidi.png"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">
                          Syed Kazim Abbas Abidi
                        </h4>
                        <p>
                          IBA Karachi
                          <br />
                          Department: Corporate and Investment Banking
                          <br />
                          2022
                        </p>
                      </div>
                    </div>
                    <div className="d-block d-lg-none">
                      <img
                        className="d-block mx-auto"
                        src="/images/theleague/internship/Syed-Kazim-Abbas-Abidi.png"
                      />
                      <div className="media-body-m center-block">
                        <h4 className="media-heading">
                          Syed Kazim Abbas Abidi
                        </h4>
                        <p>
                          IBA Karachi
                          <br />
                          Department: Corporate and Investment Banking
                          <br />
                          2022
                        </p>
                      </div>
                    </div>
                    <h3>
                      “My experience with HBL was full of learning and
                      character-building for me. From the very first day was
                      welcomed so warmly that it gave me much more confidence to
                      give my all efforts. HR and my department made sure that I
                      rejoice brimful and comprehensive head-on experience
                      during my internship. During my internship, while working
                      with my colleagues and seniors, I got to learn a new
                      interactive skill as how to communicate with different
                      people. I am grateful for the opportunity HBL offered.
                      During my six-week internship, alongside learning, I made
                      many friends and memories which I will adore for a long
                      time.”
                    </h3>
                  </div>
                </div>
              </div>
              <p>
                <a
                  className="carousel-control-prev"
                  href="#carousel-example-generic"
                  role="button"
                  data-bs-slide="prev"
                >
                  <img src="/images/theleague/internship/testi-arrow-left.png" />
                </a>

                <a
                  className="carousel-control-next"
                  href="#carousel-example-generic"
                  role="button"
                  data-bs-slide="next"
                >
                  <img src="/images/theleague/internship/testi-arrow-right.png" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="intern-sl">
        <div className="container">
          <div className="text-center camp-dr">
            <h2>The League's Journey</h2>
            <div className="position-relative">
              <div className="swiper internslide">
                <div className="swiper-wrapper">
                  {Interndata.map((val, index) => {
                    return (
                      <Journey
                        key={val.id}
                        imgsrc={val.imgsrc}
                        title={val.title}
                        link={val.link}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="intern-page">
        <div class="elig">
          <div class="container">
            <h2>Eligibility</h2>
            <p>
              Currently enrolled in 3rd year of Bachelor’s programme/ 1st year
              of Master’s programme
            </p>
            <a
              href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12001/job/14962/"
              class="butt"
              target="_blank"
            >
              Click here to Apply
              <img src="/images/theleague/internship/button-right-arrow.png" />
            </a>
            {/* <!-- <h3>Internship Applications are closed for the Year 2018.</h3>  -->
      <!-- <h3>For further queries you can contact us at</h3> -->
      <!-- <h3>Applications for The League Internship Programme 2019 will begin soon! </h3> --> */}
            <div class="s-icon">
              <li>
                <a
                  href="https://web.facebook.com/HBLTheLeague/"
                  target="_blank"
                >
                  <img src="/images/theleague/internship/fb-icon.png" />
                </a>
              </li>
              <li>
                <a href="mailto:theleague@hbl.com">
                  <img src="/images/theleague/internship/email-icon.png" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Leagueinternship;
